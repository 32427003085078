import { Button } from '@material-ui/core'
import { Apps, Dns, Folder, Laptop, NewReleases, People, Person, Settings } from '@material-ui/icons'
import clsx from 'clsx'
import DatabaseIcon from 'Components/CustomIcons/Database'
import { _ } from 'Core'
import { store } from 'index'
import moment from 'moment'
import { addError, removeError } from 'Redux/Actions/ErrorPoolAction'
import { twMerge } from 'tailwind-merge'
import { fillSelectedDevice, fillSelectedIdp } from '../Redux/Actions/DataStoreActions'
import { closeSnackbar, enqueueSnackbar } from '../Redux/Actions/NotificationsAction'
import { getFlatResourceKindGrants } from './ActionZActionCheckHelper'
import { getPolicyByRoleName } from './PolicyHelper'
import {
  cancelationModalInfo,
  formatTime,
  getAccountIcon,
  getAccountImage,
  getAccountResourceName,
  getCredentialsAssignments,
  getDisplayName,
  getRoleAccountID,
  prepareAdminApprovalReqList,
  prepareApprovalReqList,
  prepareCredentialTypes,
  prepareDevicesList,
  prepareGroupsList,
  prepareIdpsList,
  prepareRecordingsList,
  prepareServerGroupsList,
  prepareServersList,
  prepareSessionReplays,
  prepareUserAccountsList,
  prepareUserList,
  randomStringGenerator
} from './PureHelperFuctions'
/**
 *
 * @param {number} microSeconds defines how long the execution should b paused
 * @returns a promise that resolves after specified microSeconds
 */
export const delay = (microSeconds) => new Promise((resolve) => setTimeout(() => resolve(), microSeconds))

export const deepClone = (data) => {
  return JSON.parse(JSON.stringify(data))
}
export const deepCompareProps = (next, prev) => {
  return _.isEqual(next, prev)
}

/**
 * @param { Error } error actual error/exception that was thrown
 * @param { String } methodName which function threw the exception
 * @param { String } fileName file i which the exception was thrown
 */
export const errorHandler = (error, methodName, fileName) => {
  console.log(`Error in ${methodName} method ${fileName} file.`, error)
}
/**
 * Adds or removes error messages from redux
 */
export const errorPoolHelper = (resourceType, resourceId, message, intention) => {
  console.log('errorPoolHelper: ')
  console.log('params', { resourceType, resourceId, message, intention })
  if (intention === 'Add') {
    store.dispatch(addError({ resourceType, resourceId, message }))
  } else {
    store.dispatch(removeError({ resourceType, resourceId }))
  }
}

/** Returns current state of user from redux */
export const getUserInfo = () => {
  const { user } = store.getState()
  return user
}

export { getAccountImage }
export { getAccountIcon }
export { getAccountResourceName }
export { prepareUserList }
/**
 * Prepares list for devices list table
 */
export { prepareDevicesList }
/**
 * Prepares list for groups list table
 */
export { prepareGroupsList }
/**
 * Prepares list for server groups list table
 */
export { prepareServerGroupsList }
/**
 * Prepares list for idps list table
 */
export { prepareIdpsList }
/**
 * Prepares list for Servers list table
 */
export { prepareServersList }
/**
 * Prepares list for Recordings list table
 */
export { prepareRecordingsList }
/**
 * Prepares list for User Accounts List list table
 */
export { prepareUserAccountsList }
/**
 * Prepares list for Approval Requests list table
 */
export { prepareApprovalReqList }
/**
 * Prepares list for Approval Requests list table for admin
 */
export { prepareAdminApprovalReqList }
/** convert time to server acceptable format */
export { formatTime }
/** Data to be populated in request cancellation modal */
export { cancelationModalInfo }
/** get policy json by role name */
export { getPolicyByRoleName }
/** prepares data for sessions replay */
export { prepareSessionReplays }
/** prepares data for Credential Types */
export { prepareCredentialTypes }
/** prepares data for Credential Type */
export { getCredentialsAssignments }
/** Gives back random string of desired length  */
export { randomStringGenerator }
export { getDisplayName }
/** Gives AccountID of a Role */
export { getRoleAccountID }

/**
 *  check if user is unique
 */
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

/**
 *
 * @param {string} string The CIDR String to test for.
 * @returns {boolean} True if `string` is a valid CIDR Notation else false
 */

export const isCIDR = (string) => {
  const cidrRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))$/gim
  return cidrRegex.test(string)
}

/**
 *  check if a property is unique accross collection
 */
export const confirmUniqueness = (value, storeName, propertyExtractort = (e) => e?.ObjectMeta?.Name) => {
  const storeData = store.getState()
  console.log(
    'confirmUniqueness',
    value,
    storeData[storeName].data.map((dbObject) => propertyExtractort(dbObject))
  )
  const exists = storeData[storeName].data.some(
    (dbObject) => value && propertyExtractort(dbObject)?.toLowerCase() === value.toLowerCase()
  )
  /**
   * If there is no existing object with same property, property is unique
   **/
  return !exists
}

/**
 * This function returns a new object with just the properties from `filterList`
 * All other properties are excluded.
 * @param {string[]} filterList The keys to take from `srcObject`.
 * @param {object} srcObject The  object to filter properties from.
 * @returns {object}
 */

export const filterObject = (filterList = [], srcObject = {}) => {
  const obj = {}
  Object.keys(srcObject).forEach((key) => {
    const filterListKey = filterList.find((item) => item === key)
    if (filterListKey) obj[filterListKey] = srcObject[key]
  })
  return obj
}

/**
 *
 * @param {import('./types').Status} Status The object with Status property or J
 * @returns {import('./types').ConnectionStatus}
 */
export const getConnectionStatus = (Status) => {
  /**
   * @type {import('./types').ConnectionStatus}
   */
  let status = 'unknown'
  if (Status.State === '1') status = 'online'
  else if (Status.State === '0' && Status.Error === '') status = 'progress'
  else if (Status.State === '0' && Status.Error.length) status = 'error'
  else if (Status.State === '') status = 'offline'
  return status
}

/**
 * This function loops through `values` of `obj` and returns `true` if,
 * anyone of the value in the object includes the `searchText`
 * @param {string} string The search query string.
 * @param {object} obj The object that needs to be searched into.
 * @returns {boolean} `True` if any one of the value in the object matches `string`
 */

export const objectIncludes = (string, obj = {}) => {
  for (const value of Object.values(obj)) {
    /*
     * Incase the value is an nested object, we recurse through the object by calling this function
     * again with value and same string.
     * Note: typeof null === 'object' is true, but lodash.isObject(null) is false
     */
    if (_.isObject(value) && objectIncludes(string, value)) return true
    /*
      * This is the main logic
      TODO: enable case-sensitive search
    */
    if (String(value).toLowerCase().includes(string.toLowerCase())) return true
  }
  return false
}

/**
 * Shows notification
 * @param {string} message
 * @param {'error'|'warning'|'info'} [variant]
 * @param {boolean} persist
 * @param {'top'|'bottom'} vertical
 * @param {'left'|'right'} horizontal
 */
export const enqueueNotification = (
  message = '',
  variant = 'warning',
  persist = false,
  vertical = 'bottom',
  horizontal = 'left'
) => {
  console.error(message)
  store.dispatch(
    enqueueSnackbar({
      message: message,
      options: {
        persist: persist,
        key: new Date().getTime() + Math.random(),
        variant: variant,
        action: (key) => (
          <Button style={{ color: 'white' }} onClick={() => store.dispatch(closeSnackbar(key))}>
            X
          </Button>
        ),
        anchorOrigin: {
          vertical: vertical,
          horizontal: horizontal
        }
      }
    })
  )
}

/**
 *  Sets Selected Users to redux
 **/
export const setSelectedUser = (id, userList) => {
  const selectedUser = userList.find((user) => user.ObjectMeta.Name === id)
  return selectedUser
}
/**
 *  Sets Selected Group to redux
 **/
export const setSelectedGroupHelper = (Name, groupList) => {
  const targetGroup = groupList.find((group) => group.ObjectMeta.Name === Name)
  if (targetGroup) {
    const selectedGroup = deepClone(targetGroup)
    return selectedGroup
  }
  return {}
}
/**
 *  Sets Selected Idp to redux
 **/
export const setSelectedIdp = (id) => {
  const { idpList } = store.getState()
  console.log('IDP_LIST: ', idpList)
  const SELECTED_IDP = idpList.data.find((group) => group.ObjectMeta.Name === id)
  store.dispatch(fillSelectedIdp(SELECTED_IDP || {}))
}

/**
 *  Sets Selected Idp to redux
 **/
export const setSelectedDevice = (Name) => {
  const { deviceList } = store.getState()
  console.log('DEVICE_LIST: ', deviceList, Name)
  const SELECTED_DEVICE = deviceList.data.find((device) => device.ObjectMeta.Name === Name)
  store.dispatch(fillSelectedDevice(SELECTED_DEVICE || {}))
  return SELECTED_DEVICE
}

/** SELECTED USER'S TABLE FUNCTIONS GO HERE */
/**
 * Prepares list for SELECTED USER'S groups list table
 */
export const prepareSelectedUserGroupsList = (groupList, selectedUser) => {
  let preparedGroupList = groupList.map((group) => {
    return {
      Name: group.ObjectMeta.Name,
      Description: group.Spec.Description,
      Source: group.ObjectMeta.Source,
      Status: group.Status.Status,
      ID: group.ObjectMeta.ID
    }
  })
  if (selectedUser && Object.keys(selectedUser).length > 0) {
    const USER_GROUPS = selectedUser.Spec ? selectedUser.Spec.Groups.ObjectRef : []
    console.log('USER_GROUPS: ', USER_GROUPS)
    preparedGroupList = preparedGroupList.filter((group) => USER_GROUPS.some((e) => e.RefID === group.ID))
    return preparedGroupList
  } else {
    return []
  }
}
export const prepareUserGroupsList = (groupList, user) => {
  if (user && Object.keys(user).length > 0) {
    const USER_GROUPS = user.Spec.Groups.ObjectRef.filter((group) => group.RefKind === 'Group').map(
      (group) => group.RefID
    )
    const preparedGroupList = groupList.filter((group) => USER_GROUPS.includes(group.ObjectMeta.ID))
    return preparedGroupList
  } else {
    return []
  }
}
export const prepareGroupUsersList = (userList, group) => {
  if (group && Object.keys(group).length > 0) {
    const preparedGroupUsersList = []
    group.Spec.Users.ObjectRef.forEach((userRef) => {
      const user = userList.find(user => user.ObjectMeta.ID === userRef.RefID)
      if (user) preparedGroupUsersList.push(user)
    })
    return preparedGroupUsersList
  } else {
    return []
  }
}

/**
 * Prepares list for devices list table
 */
export const prepareSelectedUserDevicesList = (DEVICE_LIST, selectedUser) => {
  let preparedDeviceList = DEVICE_LIST.map((device) => {
    return {
      UserName: device.UserName,
      DeviceName: device.Attributes.DeviceName,
      OperatingSystem: device.Attributes.OperatingSystem,
      Model: device.Attributes.Model,
      CreatedAt: device.ObjectMeta.CreatedAt,
      ID: device.ObjectMeta.ID
    }
  })
  if (selectedUser && Object.keys(selectedUser).length > 0) {
    const UserName = selectedUser.ObjectMeta.Name
    console.log('USER_NAME: ', UserName)
    preparedDeviceList = preparedDeviceList.filter((device) => UserName === device.UserName)
    return preparedDeviceList
  } else {
    return []
  }
}
/**
 * Prepares list for devices list table
 */
export const prepareUserDevicesList = (DEVICE_LIST, UserName) => {
  console.log('USER_NAME: ', UserName)
  const preparedDeviceList = DEVICE_LIST.filter((device) => UserName === device.UserName)
  return preparedDeviceList
}
/**
 *
 * @param {Array.<Object>} CREDENTIAL_LIST list of all the credential instance
 * @param {Object} user current user whose credentials are to be listed
 * @param {Array.<String>} userGroupIds list of group ids this user belongs to
 * @returns Credetial list of specified user
 */
export const filterUserCredentials = (CREDENTIAL_LIST = [], user, userGroupIds) => {
  let preparedCredentialList = []
  if (user && Object.keys(user).length > 0) {
    const ID = user.ObjectMeta.ID
    /** Filter out all credentials issued to this user */
    preparedCredentialList = CREDENTIAL_LIST.filter((credential) => {
      const issue = credential.Spec.IssuedTo
      if (issue.RefKind === 'User') {
        /** Credential is issued to directly this user */
        if (issue.RefID === ID) {
          return true
        }
      } else if (issue.RefKind === 'Group') {
        /** Credential is issued to one of  this user's group */
        if (userGroupIds.includes(issue.RefID)) {
          return true
        }
      }
      return false
    })
  }
  return preparedCredentialList
}
export const prepareUserCredentialList = (CREDENTIAL_LIST = [], user, userGroupIds) => {
  console.log('TRACK ISSUE CREDENTIAL_LIST in prepare', CREDENTIAL_LIST)
  let preparedCredentialList = filterUserCredentials(CREDENTIAL_LIST, user, userGroupIds)
  preparedCredentialList =
    preparedCredentialList &&
    preparedCredentialList.map((device) => {
      return {
        Name: device.ObjectMeta.Name,
        Status: device.Status.Status,
        ID: device.ObjectMeta.ID
      }
    })
  return preparedCredentialList || []
}

export const selectedGroupCredentials = (CREDENTIAL_LIST, group) => {
  let preparedCredentialList
  if (group && Object.keys(group).length > 0) {
    const ID = group.ObjectMeta.ID
    console.log('CREDENTIAL_LIST: ', ID, CREDENTIAL_LIST)
    preparedCredentialList = CREDENTIAL_LIST.filter((credential, i) => {
      const issuedTo = credential.Spec.IssuedTo
      // Each credential has a issuedTo array
      if (issuedTo && issuedTo.RefKind === 'Group') {
        if (issuedTo.RefID === ID) {
          return true
        }
      }
      return false
    })
  }

  return preparedCredentialList || []
}

export const addGroupToUser = (userName, groupId) => {
  const { userList } = store.getState()
  const user = deepClone(userList.data.find((item) => item.ObjectMeta.Name === userName))
  const newGroupEntry = { RefID: groupId, RefKind: 'Group' }
  const Groups = user.Spec.Groups
  console.log('Groups: ', Groups)
  if (Groups && Groups.ObjectRef) {
    Groups.ObjectRef = [...Groups.ObjectRef, newGroupEntry]
  } else {
    Groups.ObjectRef = [newGroupEntry]
  }
  const newCredential = {
    ObjectMeta: user.ObjectMeta,
    Spec: {
      ...user.Spec,
      Groups: Groups
    },
    Status: user.Status
  }
  return newCredential
}
export const removeGroupFromUser = (userName, groupId) => {
  console.log('userName: ', userName)
  console.log('groupId: ', groupId, typeof groupId)
  const { userList } = store.getState()
  const user = deepClone(userList.data.find((item) => item.ObjectMeta.Name === userName))
  const Groups = user.Spec.Groups
  if (Groups && Groups.ObjectRef) {
    Groups.ObjectRef = Groups.ObjectRef.filter((group) => group.RefID !== groupId && group.RefKind === 'Group')
    console.log('Groups.ObjectRef: ', Groups.ObjectRef)
  }
  const newCredential = {
    ObjectMeta: user.ObjectMeta,
    Spec: {
      ...user.Spec,
      Groups: Groups
    },
    Status: user.Status
  }
  return newCredential
}

export const addGroupToCredentials = (credentialName, groupId) => {
  const { credentialsList } = store.getState()
  const credential = credentialsList.data.find((item) => item.ObjectMeta.Name === credentialName)
  const issue = { RefKind: 'Group', RefID: groupId }
  const IssuedTo = credential.Spec.IssuedTo
  if (IssuedTo && IssuedTo.ObjectRef) {
    IssuedTo.ObjectRef.push(issue)
  } else {
    IssuedTo.ObjectRef = [issue]
  }
  const newCredential = {
    ObjectMeta: credential.ObjectMeta,
    Spec: {
      ...credential.Spec,
      IssuedTo: IssuedTo
    },
    Status: credential.Status
  }
  return newCredential
}
export const removeGroupFromCredentials = (credentialName, groupId) => {
  console.log('groupId: ', groupId, typeof groupId)
  const { credentialsList } = store.getState()
  const credential = credentialsList.data.find((item) => item.ObjectMeta.Name === credentialName)
  const IssuedTo = credential.Spec.IssuedTo
  if (IssuedTo && IssuedTo.ObjectRef) {
    IssuedTo.ObjectRef = IssuedTo.ObjectRef.filter(
      (issue) => (issue.RefKind === 'Group' && issue.RefID !== groupId) || issue.RefKind === 'User'
    )
    console.log('IssuedTo.ObjectRef: ', IssuedTo.ObjectRef)
  }
  const newCredential = {
    ObjectMeta: credential.ObjectMeta,
    Spec: {
      ...credential.Spec,
      IssuedTo: IssuedTo
    },
    Status: credential.Status
  }
  return newCredential
}

export const getResourceImage = (type, className) => {
  switch (type) {
    case 'Role':
      return 'img/icons/iam-role.png'
    case 'AppRole':
      return 'img/icons/iam-role.png'
    case 'Server':
      return <Dns fontSize='large' className={className} />
    case 'User':
      return <Person fontSize='large' className={className} />
    case 'Group':
      return <People fontSize='large' className={className} />
    case 'Device':
      return <Laptop fontSize='large' className={className} />
    case 'Settings':
      return <Settings fontSize='large' className={className} />
    case 'Project':
      return <Folder fontSize='large' className={className} />
    case 'IamAction':
      return <NewReleases fontSize='large' className={className} />
    case 'Application':
      return <Apps fontSize='large' className={className} />
    case 'Database':
      return <DatabaseIcon fontSize='large' className={className} />
    default:
      return 'img/icons/iam-role.png'
  }
}

export const getUserRole = (user) => {
  const state = store.getState()
  const groupList = state.groupList.data
  const adminGroup = groupList.find((group) => group.ObjectMeta.Name === 'admin')
  const adminGroupId = adminGroup ? adminGroup.ObjectMeta.ID : false
  if (user && user.Spec && adminGroupId) {
    const userGroupList = user.Spec.Groups.ObjectRef
    // Check wheter user is part of the admin group
    const isAdmin =
      userGroupList && userGroupList.some((group) => group.RefID === adminGroupId && group.RefKind === 'Group')
    return isAdmin ? 'admin' : 'user'
  }

  return 'user'
}

export const getAdminGroups = (groupList) => {
  return groupList.filter((group) => group.ObjectMeta.Name === 'admin')
}

/**
 *
 * @param {object} user The user to check for
 * @param {object} group The group to check `user` into
 * @returns {boolean} `true` if user belongs to this group else `false`
 */
export const isUserFromGroup = (user, group) => {
  const userGroupRefs = user?.Spec?.Groups?.ObjectRef
  const groupID = group?.ObjectMeta?.ID
  /**
   * If nno group ID or no groups in user return false
   */
  if (!groupID) return false
  if (!userGroupRefs) return false
  const userGroupIds = []
  userGroupRefs.forEach((element) => {
    if (element.RefKind === 'Group' && element.RefID) userGroupIds.push(element.RefID)
  })
  return userGroupIds.includes(groupID)
}

/**
 *
 * @param {object} user
 * @param {object} group
 * @param {boolean} invertedSearch  To handle backend inconsistency. If true, traverse all users to see how many users have admin group in there Group ref
 * @param {Array} userList required if inverted search is true
 * @returns {boolean}
 */
export const isUserLastMemberOfGroup = (user, group, invertedSearch = false, userList = []) => {
  /**
   * Check if the user belongs to this group if not return false
   */
  if (!isUserFromGroup(user, group)) return false
  const groupUserRefs = group?.Spec?.Users?.ObjectRef
  /**
   * Since, we already know that user belongs to this group,
   * check if the group has more than one user
   */
  // TODO remove invertedSearch prop once backend issue is resolved
  if (invertedSearch) {
    /**
     * As there is a backend in consistency in group's user ref
     * We can not depend on `group?.Spec?.Users?.ObjectRef` for now
     */
    const adminGroupUsers = prepareGroupUsersList(userList, group)
    console.log('adminGroupUsers', adminGroupUsers)
    return adminGroupUsers.length === 1
  } else if (!groupUserRefs || groupUserRefs.length > 1) return false
  /**
   * BY this time we know, user belongs to this group and there is only one user hence, this is the only user in the
   * group
   */
  return true
}

/**
 * Tell is a resource is granted or not, Gives a list of allowed actions
 * @param {{ObjectMeta:{ID:string,Kind:string}}} resource
 * @param {{ResourceKind:{Action:{Resources:Array<{Resource:{RefKind:string,RefID:'string'}}>}}}} resourceKindGrants reference list of granted resources (servers or roles)
 * @returns {Array<string> | boolean} isGranted
 */
export const isResourceGranted = (resource, resourceKindGrants) => {
  try {
    const grantedForActions = []
    const { ID, Kind } = resource.ObjectMeta
    const grantedResourceRefs = getFlatResourceKindGrants(resourceKindGrants, Kind)
    console.log('isResourceGranted kind:', Kind, ' ID:', ID, ' grantedResourceRefs:', grantedResourceRefs)
    grantedResourceRefs.forEach((ref) => {
      if (ref.Resource.RefKind === Kind && ref.Resource.RefID === ID) {
        grantedForActions.push(ref.Action)
      }
    })
    return grantedForActions.length > 0 ? grantedForActions : false
  } catch (error) {
    console.log('error in checking grant of', resource)
  }
}

export const getUserProfile = (user, profiles = []) => {
  return profiles.find((profile) => profile.UserName === user.ObjectMeta.Name)
}

/**
 * Gives list of servers with grant status
 * @param {{ResourceKind:{Action:{Resources:Array<{Resource:{RefKind:string,RefID:'string'}}>}}}} resourceKindGrants reference lis of granted server
 * @param {Array<{ObjectMeta:{ID:string,Kind:string},Spec:{State:string}}>} targetList
 * @returns {Array} list of server with grant status
 */
export const getProfileTargetList = (resourceKindGrants, targetList = [], withUnmanaged = false) => {
  const filteredTargetList = []
  targetList.forEach((target) => {
    if (target.Spec.State === 'managed' || withUnmanaged) {
      const grantedToUser = isResourceGranted(target, resourceKindGrants)
      const Access = grantedToUser ? 'GRANTED' : 'UNGRANTED'
      console.log('target Grant', Access)
      // const Access = 'GRANTED'
      const metaTarget = {
        Source: target,
        Meta: {
          Access
        },
        actionsGranted: grantedToUser || []
      }
      filteredTargetList.push(metaTarget)
    }
  })
  return filteredTargetList
}

export const getApplicationFromRole = (role, applicationList) => {
  const applicationID = role.Spec?.Application?.RefID
  if (!applicationID) return {}
  return applicationList.find((app) => app.ObjectMeta.ID === applicationID)
}

/**
 * Gives list of roles with grant status
 * @param {{ResourceKind:{Action:{Resources:Array<{Resource:{RefKind:string,RefID:'string'}}>}}}}  resourceKindGrants reference lis of granted roles
 * @param {Array<{ObjectMeta:{ID:string,Kind:string},Spec:{State:'managed'|'unmanaged'}}>} appRoles
 * @returns {Array} list of roles with grant status
 */
export const getAppRolesFromProfile = (resourceKindGrants, appRoles = []) => {
  const filteredAppRoles = []
  appRoles.forEach((appRole) => {
    if (appRole.Spec.State === 'managed') {
      const granted = isResourceGranted(appRole, resourceKindGrants)
      const Access = granted ? 'GRANTED' : 'UNGRANTED'
      const metaObject = {
        Source: appRole,
        Meta: {
          Access
        },
        actionsGranted: granted || []
      }
      filteredAppRoles.push(metaObject)
    }
  })
  return filteredAppRoles
}

export const isProjectGranted = (project = {}, approvalReqs = []) => {
  const projectRequest = approvalReqs.find(
    (e) => _.get(e, 'Spec.Resources.Resource[0].GcpRoleTarget.RefID') === _.get(project, 'ObjectMeta.ID', null)
  )
  if (!projectRequest) return false
  else {
    return (
      projectRequest.Status === 'Approved' &&
      moment().diff(moment(projectRequest.Spec.NotAfter)) < 0 &&
      moment().diff(moment(projectRequest.Spec.NotBefore)) > 0
    )
  }
}

export const createDBCommand = (db) => {
  if (_.get(db, 'ObjectMeta.Kind') !== 'Database') return null
  const typeMap = {
    MySQL: 'mysql',
    RDS: 'rds'
  }
  const PROGRAM = typeMap[_.get(db, 'Spec.DBType', 'MySQL')]
  if (!PROGRAM) return null
  const HOST = _.get(db, 'Spec.DNSNames.Elems[0]')
  if (!HOST) return
  const PORT = _.get(db, 'Spec.Port')
  const PROTOCOL = 'TCP'
  const USER = _.get(db, 'Spec.Username', 'procyon')
  // *      mysql --host=testnysqldb.db.procyon.ai --port=3306 --protocol=TCP --user=procyon
  return `${PROGRAM} --host=${HOST} --port=${PORT} --protocol=${PROTOCOL} --user=${USER}`
}

/**
 * 
 * @param {import('clsx').ClassValue[]} classValues 
 */
export function cn(...classValues) {
  return twMerge(clsx(classValues))
}