import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// import Login from '../Components/Login/Login'

export default function PublicAuthRoute ({
  children,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route {...rest}>{isAuthenticated !== '' ? children : <Redirect to='/idasignin' />}</Route>
  )
}
