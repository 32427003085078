export const ADD_ERROR = 'ADD_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'

const initState = {
  regions: { 1: 'aaa' },
  vpcs: {},
  servers: {}
}
const errorPoolReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_ERROR: {
      const { resourceType, resourceId, message } = action.payload
      const newState = {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [resourceId]: message
        }
      }
      return newState
    }
    case REMOVE_ERROR: {
      const { resourceType, resourceId } = action.payload
      console.log('REMOVE_ERROR :: { resourceType, resourceId }: ', { resourceType, resourceId })
      const newState = { ...state }
      if (newState[resourceType]) {
        delete newState[resourceType][resourceId]
      }
      return newState
    }
    default:
      return state
  }
}

export default errorPoolReducer
