import { ThemeProvider } from '@material-ui/core'
import { configureStore } from '@reduxjs/toolkit'
import FallBack from 'Components/Fallback'
import SITE_CONFIG from 'Core/Config'
import Theme from 'Core/Theme'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import rootReducer from 'Redux/Reducers'
import App from './App'
import './index.css'
import './normalize.css'
const { publicUrl } = SITE_CONFIG

export const store = configureStore({
  reducer: rootReducer
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <Suspense fallback={<FallBack />}>
      <Router basename={publicUrl}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </Router>
    </Suspense>
  </Provider>
)
