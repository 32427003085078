import React from 'react'
import { Route } from 'react-router-dom'
// import Login from '../Components/Login/Login'

export default function AuthenticatedRoute ({
  children,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route {...rest}>{isAuthenticated === true ? children : ''}</Route>
  )
}
