export const user = {
  general: false,
  showDatabaseDetailsPage: false,
  showAccoutsPage: false,
  showAccoutsBrowserPage: false,
  showUsersAccoutsPage: true,
  showUsersIamRolesPage: true,
  showUsersDataSourcePage: true,
  showUsersServersPage: true,
  showApprovalRequestPage: false,
  showApprovalRequestDetailsPage: false,
  showServersPage: false,

  showCredentialsPage: false,

  showPolicyPage: false,

  showDashboardPage: false,
  showGettingStartedPage: false,

  showUsersPage: false,
  showUserInformationPage: false,
  showUserDetailsPage: false,

  showGroupDetailsPage: false,
  showDevicesPage: false,

  showIdpDetailsPage: false,
  showIdpsPage: false,

  showDeviceDetailsPage: false,

  showAccessManagementGroupLink: false,
  showResourcesGroupLink: true,
  showApplicationsPage: true,
  showProjectsPage: false,
  showTeamGroupLink: true,
  showDashBoardGroupLink: false,
  showReportsGroupLink: false,
  showRequestGroupLink: true,

  showFavoritesPage: true,

  showApprovalPage: true,

  showLogsPage: false,

  showRecordingPage: false,

  showWebSshPage: true,
  showSettingsPage: false,
  showIntegrationsPage: false,
  showGlobalResourcesPage: false,
  showUsersDevicesPage: true,
  showUserFavourites: true,

  showIamActions: true,
  showTargets: true,
  showServiceAccountsPage: false
}
