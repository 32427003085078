import {
  FILL_SELECTED_ACCOUNT,
  FLUSH_SELECTED_ACCOUNT,
  FILL_SELECTED_IDP,
  FLUSH_SELECTED_IDP,
  FILL_SELECTED_DEVICE,
  FLUSH_SELECTED_DEVICE,
  FILL_FAVORITE_IAM_LIST,
  FLUSH_FAVORITE_IAM_LIST,
  FILL_SESSION_REPLAYS,
  FLUSH_SESSION_REPLAYS,
  FILL_FAVORITE_SERVER_LIST

} from '../Reducers/DataStoreReducer'

export const fillSeverGroupList = (payload) => {
  return ({
    type: FILL_SERVER_GROUP_LIST,
    payload
  })
}

export const flushSeverGroupList = () => {
  return ({
    type: FLUSH_SERVER_GROUP_LIST
  })
}

export const fillFavoriteIamList = (payload) => {
  return ({
    type: FILL_FAVORITE_IAM_LIST,
    payload
  })
}
export const fillFavoriteServerList = (payload) => {
  return ({
    type: FILL_FAVORITE_SERVER_LIST,
    payload
  })
}

export const flushFavoriteIamList = () => {
  return ({
    type: FLUSH_FAVORITE_IAM_LIST
  })
}

export const fillApplicationList = (payload) => {
  return ({
    type: FILL_APPLICATION_LIST,
    payload
  })
}

export const flushApplicationList = () => {
  return ({
    type: FLUSH_APPLICATION_LIST
  })
}

export const fillSelectedIdp = (payload) => {
  return ({
    type: FILL_SELECTED_IDP,
    payload
  })
}

export const flushSelectedIdp = () => {
  return ({
    type: FLUSH_SELECTED_IDP
  })
}
export const fillSelectedDevice = (payload) => {
  return ({
    type: FILL_SELECTED_DEVICE,
    payload
  })
}

export const flushSelectedDevice = () => {
  return ({
    type: FLUSH_SELECTED_DEVICE
  })
}

export const fillSelectedAccount = (payload) => {
  return ({
    type: FILL_SELECTED_ACCOUNT,
    payload
  })
}

export const flushSelectedAccount = () => {
  return ({
    type: FLUSH_SELECTED_ACCOUNT
  })
}

export const fillSessionReplays = (payload) => {
  return ({
    type: FILL_SESSION_REPLAYS,
    payload
  })
}

export const flushSessionReplays = () => {
  return ({
    type: FLUSH_SESSION_REPLAYS
  })
}
