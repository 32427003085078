import axios from 'axios'
import SITE_CONFIG from 'Core/Config'
import { debug } from 'Utils/DebugHelper'
import { enqueueNotification, errorPoolHelper } from '../Utils/Helpers'
const errorListenters = ['regions', 'servers', 'vpcs']

const handleError = (error) => {
  if (debug.shouldNotifyError()) {
    if (debug.verboseErrorNotification()) {
      const errorMessage = JSON.stringify(error)
      enqueueNotification(errorMessage, 'error')
    } else {
      const errorMessage = `Error Message: ${error?.response?.data}, Response Status: ${error?.response?.status}`
      enqueueNotification(errorMessage, 'error')
    }
  }
  return error
}
const onApiError = async (error, endPoint, method, formData) => {
  console.log('error: ', endPoint, error)

  // redirect user ida sign in page on status 401
  if(error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/ui/idasignin'
  }
  
  try {
    if (debug.shouldFallbackToMock()) {
      enqueueNotification('Mock: ' + endPoint, 'info')
      if (method === 'GET') {
        const mockResponse = await axios({
          method: 'GET',
          url: `/temp/${endPoint}/${method}.json`
        })
        return mockResponse
      } else {
        return formData
      }
    } else {
      return handleError(error)
    }
  } catch (error) {
    return handleError(error)
  }
}

export const getRequest = async (apiKey, endPoint, tenant, apiAccessor = 'api') => {
  try {
    const serverresponse = await axios({
      method: 'GET',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: `/${apiAccessor}/${tenant}/v1/${endPoint}`
    })
    return serverresponse
  } catch (error) {
    return await onApiError(error, endPoint, 'GET')
  }
}
export const getElastic = async (apiKey, endPoint, apiAccessor = 'api', data = {}) => {
  try {
    console.log('getElastic Ssh', endPoint, data)
    const serverresponse = await axios({
      method: 'POST',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: (apiAccessor ? `/${apiAccessor}/${endPoint}` : `/${endPoint}`),
      data: data // {query:{}}
    })
    return serverresponse
  } catch (error) {
    if (error.response.status === 404) {
      /**
       * Check redux-toolkit setup.
       * sliceGenerate() will set empty array as state if payload.data is false
      **/
      return { data: false, error }
    }
    return await onApiError(error, endPoint, 'GET')
  }
}

export const postRequest = async (apiKey, endPoint, tenant, data) => {
  try {
    if (data && data.ObjectMeta) {
      data.ObjectMeta.Tenant = tenant
      data.ObjectMeta.Namespace = SITE_CONFIG.Namespace
    }
    return await axios({
      method: 'POST',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: `/api/${tenant}/v1/${endPoint}`,
      data: JSON.stringify(data)
    })
  } catch (error) {
    if (errorListenters.includes(endPoint)) {
      errorPoolHelper(endPoint, data.ObjectMeta.ID, JSON.stringify(error))
    }
    return await onApiError(error, endPoint, 'POST', data)
  }
}

export const putRequest = async (apiKey, endPoint, uniqueId, tenant, data, apiAccessor = 'api') => {
  try {
    let payload = {}
    if (data && data.ObjectMeta) {
      payload = { ...data, ObjectMeta: { ...data.ObjectMeta, Tenant: tenant } }
    } else {
      payload = data
    }
    const serverresponse = await axios({
      method: 'PUT',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: `/${apiAccessor}/${tenant}/v1/${endPoint}/${uniqueId}`,
      data: payload
    })
    // Removing error to redux store
    if (errorListenters.includes(endPoint.split('/')[0])) {
      const resourceType = endPoint.split('/')[0]
      errorPoolHelper(resourceType, data.ObjectMeta.ID)
    }
    return serverresponse
  } catch (error) {
    // Adding error to redux store
    if (errorListenters.includes(endPoint.split('/')[0])) {
      const resourceType = endPoint.split('/')[0]
      errorPoolHelper(resourceType, data.ObjectMeta.ID, JSON.stringify(error), 'Add')
    }
    return await onApiError(error, endPoint, 'PUT', data)
  }
}

export const deleteRequest = async (apiKey, endPoint, uniqueId, tenant, apiAccessor = 'api') => {
  try {
    const serverresponse = await axios({
      method: 'DELETE',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: `/${apiAccessor}/${tenant}/v1/${endPoint}/${uniqueId}`
    })
    // check the respponse and show notifications
    return serverresponse
  } catch (error) {
    return await onApiError(error, endPoint, 'DELETE', { uniqueId })
  }
}
