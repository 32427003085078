import axios from 'axios'
import * as API from '../API_LAYER'
import * as Config from './Config'
import * as Actions from '../Redux/Actions'
import Theme from './Theme'
import FeatureFlags from './FeatureFlagClass'
import _ from 'lodash'
import ApiProvider from 'API_LAYER/ApiProvider'
import * as am4core from '@amcharts/amcharts4/core'

export { default as React, Component } from 'react'
export { default as PropTypes } from 'prop-types'
export { Provider, connect, useSelector, useDispatch } from 'react-redux'
export { combineReducers } from 'redux'
export { Route, Switch, Redirect, withRouter, Link, NavLink, Prompt } from 'react-router-dom'
export { _ }
export { axios }
export { API }
export { Config }
export { Actions }
export { Theme }
export { ApiProvider }
/** Feature Flag Object can return flags | flags Can be updated as well */
const FeatureFlagsObject = new FeatureFlags()
export { FeatureFlagsObject }

// Enable queuing
// am4core.options.queue = true
// am4core.options.autoDispose = true
am4core.options.onlyShowOnViewport = true
export { am4core }

String.prototype.replaceAll = function (search, replacement) {
  try {
    var target = this
    if (!search) return ''
    return target.replace(new RegExp(search, 'g'), replacement)
  } catch (error) {
    return ''
  }
}
