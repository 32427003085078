import { ADD_ERROR, REMOVE_ERROR } from '../Reducers/ErrorPoolReducer'

export const addError = (payload) => {
  return ({
    type: ADD_ERROR,
    payload
  })
}

export const removeError = (payload) => {
  return ({
    type: REMOVE_ERROR,
    payload
  })
}
