import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiProvider } from 'Core'
export const status = {
  IDLE: 'idle',
  LOADING: 'loading',
  SILENT_LOADING: 'silentLoading',
  ERRORED: 'errored'
}
/**
 * @typedef {{ initialState: any, sliceName:string, reducers?: object, apiFn?: (() => any) | null,  endpoint: string }} SliceGeneratorParamType
 * @type SliceGeneratorParamType
 */
const sliceGeneratorParamShape = {
  initialState: [],
  apiFn: null,
  sliceName: '',
  endpoint: null,
  reducers: {}
}

const getFinalPayload = (name, payload) => {
  switch (name) {
    case 'idpList':
      return payload.data ? payload.data.IDProviders : []
    case 'webProxyActivityLogs':
      return payload.data ? payload.data.hits.hits : []
    case 'sshActivityLogs':
      return payload.data ? payload.data.hits.hits : []
    case 'eventLog':
      return payload.data ? payload.data.hits.hits : []
    case 'proxyAuditLogs':
      return payload.data ? payload.data.hits.hits : []
    case 'approvalReqList':
      return payload.data ? payload.data.ApprovalReqs : []
    case 'accountList':
      return payload.data ? payload.data.Accounts : []
    case 'appRolesList':
      return payload.data ? payload.data.AppRoles : []
    case 'applicationList':
      return payload.data ? payload.data.Applications : []
    case 'deviceList':
      return payload.data ? payload.data.Devices : []
    case 'serverGroupList':
      return payload.ServerGroups
    case 'recordingList':
      return payload.data ? payload.data.Recordings : []
    case 'serverList':
      return payload.data ? payload.data.Servers : []
    case 'policyList':
      return payload.data ? payload.data.PacPolicys : []
    case 'paaPolicyList':
      return payload.data ? payload.data.PaaPolicys : []
    case 'proxyList':
      return payload.data ? payload.data.RemoteProxys : []
    case 'sshAccessList':
      return payload ? payload?.data : {}
    case 'roleAccessList':
      return payload ? payload?.data : {}
    case 'resourceActions':
      return payload || {}
    case 'resourceKindGrants':
      return payload
    case 'projects':
      return payload.data?.Projects || []
    case 'integrations':
      return payload.data?.Integrations
    case 'medusaNodes':
      return payload.data?.MedusaNodes
    case 'slackIntegrationList':
      return payload.data?.SlackIntegrations || []
    case 'jiraIntegrationsList':
      return payload.data?.JiraIntegrations || []
    case 'slackChannelList':
      return payload.data?.SlackChannels || []
    case 'jiraProjectList':
      return payload.data?.JiraProjects || []
    case 'tenantprofiles':
      return payload.data || {}
    case 'awsResources':
      return payload?.data?.AwsResources || []
    case 'gcpResources':
      return payload?.data?.GcpResources || []
    case 'azureResources':
      return payload?.data?.AzureResources || []
    case 'iamActions':
      return payload?.data?.IamActions || []
    case 'awsCloudActions':
      return payload?.data?.ServiceActions?.ServiceActions
    case 'gcpCloudActions':
      return payload?.data?.ServiceActions?.ServiceActions
    case 'azureCloudActions':
      return payload?.data?.ServiceActions?.ServiceActions
    case 'googleIdpList':
      return payload?.data?.GoogleIDProviders
    case 'userList':
      return payload?.data?.Users
    case 'groupList':
      return payload?.data?.Groups
    case 'idpList':
      return payload?.data?.IDProviders
    case 'credentialsList':
      return payload?.data?.Credentials
    case 'databases':
      return payload?.data?.Databases
    case 'serviceAccounts':
      return payload?.data?.ServiceAccounts
    case 'kubeNamespaces':
      return payload?.data?.KubeNamespaces
    // idanalyzer
    case 'resourcediscoverytenantriskprofile':
      return payload?.data?.ResourceDiscoveryTenantRiskProfiles
    case 'resourcediscoverytenantsummary':
      return payload?.data
    case 'resourcediscoveryaccountriskprofiles':
      return payload?.data?.ResourceDiscoveryAccountRiskProfiles
    case 'resourcediscoveryaccountsummary':
      return payload?.data?.ResourceDiscoveryAccountSummarys
    default:
      return payload
  }
}
export const sliceGenerator = (options = sliceGeneratorParamShape) => {
  const opts = { ...sliceGeneratorParamShape, ...options }
  const thunk = createAsyncThunk(
    opts.sliceName,
    // @ts-ignore
    async (data = { payload: false, flags: {} }) => {
      // @ts-ignore
      if (opts.apiFn) return await opts.apiFn(data.payload)
      return await new ApiProvider(opts.endpoint).getAll()
    }
  )
  const updateThunk = createAsyncThunk(
    opts.sliceName + 'Update',
    // @ts-ignore
    async (data = { payload: false, flags: {} }) => {
      if (data.payload) return await new ApiProvider(opts.endpoint).setInstance(data.payload).put()
      else console.error('no payload provided for update', name)
    }
  )
  const deleteThunk = createAsyncThunk(
    opts.sliceName + 'Delete',
    // @ts-ignore
    async (data = { payload: false, flags: {} }) => {
      if (data.payload) return await new ApiProvider(opts.endpoint).setInstance(data.payload).delete()
      else console.error('no payload provided for update', opts.sliceName)
    }
  )
  const slice = createSlice({
    name: opts.sliceName,
    initialState: {
      status: status.IDLE,
      data: opts.initialState,
      updating: [],
      initiallyLoaded: false
    },
    reducers: opts.reducers,
    extraReducers: (builder) => {
      builder.addCase(thunk.pending, (state, action) => {
        const { arg } = action.meta
        // @ts-ignore
        if (!arg?.flags?.skipLoader) {
          state.status = status.LOADING
        } else {
          state.status = status.SILENT_LOADING
        }
      })
      builder.addCase(thunk.fulfilled, (state, action) => {
        if (!action.payload || action?.payload?.error) {
          state.status = status.ERRORED
        } else {
          state.data = getFinalPayload(opts.sliceName, action.payload)
          state.status = status.IDLE
        }
        state.initiallyLoaded = true
      })
      builder.addCase(thunk.rejected, (state, action) => {
        state.status = status.ERRORED
        state.initiallyLoaded = true
      })
      /** Update Thunk builders */
      builder.addCase(updateThunk.pending, (state, action) => {
        const {
          arg: { payload }
        } = action.meta
        state.updating.push(payload.ObjectMeta.Name)
      })
      builder.addCase(updateThunk.fulfilled, (state, action) => {
        if (action.payload && action?.payload?.data) {
          const object = action.payload.data
          state.data = state.data.map((e) => {
            if (e.ObjectMeta.ID === object.ObjectMeta.ID) return object
            return e
          })
          state.updating = state.updating.filter((e) => e !== object.ObjectMeta.Name)
        } else {
          throw Error('Invalid response')
        }
      })
      builder.addCase(updateThunk.rejected, (state, action) => {
        if (action?.payload?.ObjectMeta) {
          state.updating = state.updating.filter((e) => e !== action.payload.ObjectMeta.Name)
        }
      })
      /** Delete Thunk builders */
      builder.addCase(deleteThunk.pending, (state, action) => {
        const {
          arg: { payload }
        } = action.meta
        state.updating.push(payload.ObjectMeta.Name)
      })
      builder.addCase(deleteThunk.fulfilled, (state, action) => {
        console.log('action?.payload?.data', action?.payload?.data)
        if (action.payload && action?.payload?.data) {
          const object = action.payload.data
          console.log('action?.payload?.data object', object)
          state.data = state.data.filter((e) => e.ObjectMeta.Name !== object.ObjectMeta.Name)
          state.updating = state.updating.filter((e) => e !== object.ObjectMeta.Name)
        } else {
          throw Error('Invalid response')
        }
      })
      builder.addCase(deleteThunk.rejected, (state, action) => {
        if (action?.payload?.ObjectMeta) {
          state.updating = state.updating.filter((e) => e !== action.payload.ObjectMeta.Name)
        }
      })
    }
  })

  return {
    thunk,
    updateThunk,
    deleteThunk,
    reducer: slice.reducer,
    name: opts.sliceName
  }
}
