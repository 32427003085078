const { REQUEST_STATUS } = require('./ApprovalRequest')

const CONSTANTS = {
  approvalRequest: REQUEST_STATUS,
  /** Groups which are too important and special to be changed or harmed */
  sacredGroups: ['admin', 'everyone'],
  WHITELISTED_CLOUD_SERVICES: [
    'Amazon EC2',
    'Amazon Elastic Container Registry',
    'Amazon Elastic Kubernetes Service',
    'Amazon Elastic Container Service',
    'AWS Lambda',
    'Amazon S3',
    'Amazon RDS',
    'AWS Key Management Service',
    'AWS Secrets Manager'
  ],
  policyTypes: {
    IAMAction: 'IAMAction',
    Target: 'Target',
    App: 'App'
  },
  ActionTypes: {
    GCPIAM: 'GcpIAM',
    AWSIAM: 'AwsIAM',
    AZUREIAM: 'AzureIAM'
  },
  IAMCloudTypes: {
    AWS: 'AWS',
    GCP: 'GCP',
    AZURE: 'AZURE'
  }
}

export const IDA_Graph_Constant = {
  entitiesMap: {
      aws_iam_users: "user_name",
      aws_iam_roles: "role_name",
      aws_iam_user_attached_policies: "policy_name",
      aws_iam_user_policies: "policy_name",
      aws_iam_groups: "group_name",
      aws_resources: "policy_name",
      aws_iam_role_policies: "policy_name",
      aws_iam_role_attached_policies: "policy_name",
      aws_iam_group_policies: "policy_name",
      aws_iam_group_attached_policies: "policy_name",
      aws_ec2_instances: 'instance_id',
      aws_s3_buckets:'name',
      aws_ec2_vpcs: 'vpc_id',
      aws_rds_instances: 'account_id'
  },

  iconMap: {
    aws_iam_users: "AWS_User",
      aws_iam_user_groups: "USERGROUPS",
      aws_iam_roles: "ROLE",
      aws_iam_user_attached_policies: "POLICY",
      aws_iam_user_policies: "POLICY",
      aws_iam_groups: "USERGROUPS",
      aws_ec2_instances: "AWS_EC2_Instance",
      aws_resources: "All_Resource",
      aws_iam_user_access_keys: "ACCESS_KEY",
      aws_iam_role_policies: "POLICY",
      aws_iam_role_attached_policies: "POLICY",
      aws_iam_group_policies: "POLICY",
      aws_iam_group_attached_policies: "POLICY",
      aws_s3_buckets: 'AWS_S3',
      aws_ec2_vpcs: 'AWS_VPC',
      aws_rds_instances: 'AWS_RDS_DB'
  }, 

  iconResources: {
    'ec2':'AWS_EC2_Instance',
    'rds':'AWS_RDS_DB',
    'vpc':'AWS_VPC',
    's3': 'AWS_S3',
  }
}

export const SupportedActivityLogsResourceType = ['AppRole', 'Server']
export default CONSTANTS
