import { loginUser } from 'Redux/Actions/UserActions'
import { prepareUserObject } from 'Utils/AuthHelpers'
import { debug } from 'Utils/DebugHelper'
import { resourceActionsSlice, resourceKindGrantsSlice, userpreferencesSlice } from 'Redux/Reducers'
import { useDispatch, am4core, API, useSelector } from 'Core'
import { useHistory } from 'react-router'
import React, { useCallback, useEffect, useRef, useState } from 'react'

export const BootContext = React.createContext({ bootComplete: false })

function BootProvider (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  // @ts-ignore
  const user = useSelector((state) => state.user)
  const [bootComplete, setBootComplete] = useState(false)
  const fetched = useRef(false)

  const handleAgentContainer = useCallback(() => {
    try {
      const params = new URLSearchParams(window.location.search)
      const isAgentContainer = params.get('agent')
      if (isAgentContainer) {
        debug.setContainer('agent')
      } else {
        debug.setContainer('browser')
      }
    } catch (error) {
      console.error('error in handleAgentContainer', error)
      debug.setContainer('browser')
    }
  }, [])

  const init = useCallback(async () => {
    /** Charts should load one by one */
    am4core.options.queue = true
    /** Set current container type from url to localstorage (browser|agent)    */
    handleAgentContainer()
    const ignoredHistoryRoutes = ['/ui/register', '/ui/idasignup', '/ui/idasignin']
    const ignoreCurrentRoute = ignoredHistoryRoutes.includes(window.location.pathname)
    /** Initiate login process */
    const currentUrl = window.location.pathname
    const isSignupPage = currentUrl.includes('signupverify') || currentUrl.includes('signup') || currentUrl.includes('idasignup') || currentUrl.includes('idasignin')
    try {
      /** Makes api call to get currenly logged in user's DB Object  */
      const response = await API.checkAuth()
      if (response && response.data) {
        const user = await prepareUserObject(response.data)
        try {
          if (typeof user === 'object') user.DeviceInfo = JSON.parse(response.headers['x-auth-token'])
        } catch (error) {}
        dispatch(loginUser({ ...user }))
        if (isSignupPage && !ignoreCurrentRoute) {
          console.log('User is authenticated. No need for signup')
          history.push('/')
        }
        return
      }
      /** After all the logic, send to signup. The user is not authenticated */
      if (!isSignupPage && !ignoreCurrentRoute) {
        history.push('/idasignin')
      }
    } catch (error) {
      console.log('Bootprocess-> userObject, error', error)
      /** Redirect user to sign up app in case of a failure in login process */
      !ignoreCurrentRoute && history.push('/idasignin')
    }
  }, [handleAgentContainer])

  const loadInitialSlices = async () => {
    dispatch(userpreferencesSlice.thunk())
    const actions = await dispatch(resourceActionsSlice.thunk()).unwrap()
    dispatch(resourceKindGrantsSlice.thunk({ payload: actions }))
    fetched.current = true
    setBootComplete(true)
  }
  /** Initiate login on load */
  useEffect(() => {
      init()
  }, [])

  /** load required redux states once authenticated */
  useEffect(() => {
    if (user && user.loggedIn && fetched.current === false) loadInitialSlices()
  }, [user])

  return <BootContext.Provider value={{ bootComplete }}>{props.children}</BootContext.Provider>
}

export default BootProvider
