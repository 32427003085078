// @ts-nocheck
import DebugForm from 'Components/DebugForm'
import BootProvider from 'Core/Hooks/useBoot/BootProvider'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import AuthenticatedRoute from 'Router/AuthenticatedRoutes'
import PublicAuthRoute from 'Router/PublicAuthRoutes'
import { debug } from 'Utils/DebugHelper'

const SignupComplete = React.lazy(() => import('./Views/Signup/Components/SignupComplete'))
const Signup = React.lazy(() => import('./Views/Signup'))
const Admin = React.lazy(() => import('./Admin'))
const Register = React.lazy(() => import('./Views/Register'))
const IdanalyzerSignin = React.lazy(() => import('./features/idanalyzer/components/Signin'))
const IdanalyzerSignup = React.lazy(() => import('./features/idanalyzer/components/Signup'))
const IdanalyzerSignupComplete = React.lazy(()=> import('./features/idanalyzer/components/verify'))
const IdaLayout = React.lazy(() => import('./features/idanalyzer/layout'))
window.debug = debug
const App = () => {
  const user = useSelector((state) => state.user)
  const isAuthenticated = user?.loggedIn
  const IsToken = localStorage.getItem('authToken')

  return (
    <BootProvider>
      <Switch>
        <Route path='/signupverify' component={SignupComplete} />
        <Route path='/signup' component={Signup} />
        <Route path='/register' component={Register} />
        <Route path='/idasignin' component={IdanalyzerSignin} />
        <Route path='/idasignup' component={IdanalyzerSignup} />
        <Route path='/idasignupverify' component={IdanalyzerSignupComplete} />
        <PublicAuthRoute isAuthenticated={IsToken} path='/idanalyzer' component={IdaLayout} />
        <AuthenticatedRoute isAuthenticated={isAuthenticated} path='/' component={Admin} />
      </Switch>
      <DebugForm />
    </BootProvider>
  )
}

export default App
