import { ApiProvider, axios } from 'Core'
import { store } from 'index'

const fetchObjectAction = async (endpoint) => {
  try {
    const excludeActions = ['Read', 'Write', 'read', 'write', 'GcpIAM', 'AwsIAM', 'AzureIAM']
    const projectsIncludeActions = []
    const response = await new ApiProvider('objactions').setInstance({ ObjectMeta: { Name: endpoint } }).get()
    response.data.Actions = response.data.Actions.filter(e => {
      if (response.data.ObjectMeta.Name === 'Project' && projectsIncludeActions.includes(e.Action)) return true
      else return !excludeActions.includes(e.Action)
    })
    return response.data
  } catch (error) {
    console.error('error in fetchObjectAction', error)
    return false
  }
}
export const fetchEndpoints = async () => {
  try {
    const { user: { apiKey } } = store.getState()
    const response = await axios({
      method: 'GET',
      headers: { Authorization: apiKey ? `Bearer ${apiKey}` : '' },
      url: '/endpoints'
    })
    const endpoints = response.data.endpoints
    return endpoints
  } catch (error) {
    console.error('error in fetchEndpoints', error)
    return []
  }
}

export const populateActionObjects = async (user) => {
  try {
    const actionObjects = {}
    const endpoints = await fetchEndpoints()
    await Promise.all(endpoints.map(async (endpoint) => {
      const actionObject = await fetchObjectAction(endpoint)
      actionObjects[endpoint] = actionObject
    }))
    return actionObjects
  } catch (error) {
    console.error('error in populateActionObjects', error)
    return {}
  }
}

/**
 * Gives list of service type for a resource
 * @param {string } resourceKind eg: get this from resource.ObjectMeta.Kind
 * @param {Object} resourceActions
 * @returns {Array<string>} actions | Service type
 */
export const getActionsOf = (resourceKind, resourceActions) => {
  try {
    if (typeof resourceActions === 'object' && typeof resourceActions[resourceKind] === 'object') {
      if ('Actions' in resourceActions[resourceKind]) {
        const actions = resourceActions[resourceKind].Actions.map(e => e.Action)
        return actions
      }
    }
  } catch (error) {
    console.error('error in getActionsOf', error)
  }
  return []
}
