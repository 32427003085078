import { axios } from 'Core'
import _ from 'lodash'
import { getUserInfo, randomStringGenerator } from 'Utils/Helpers'
const validEndpoints = [
  // Maintain alphabetic order
  'accesspolicys',
  'accounts',
  'appgroups',
  'applications',
  'approles',
  'approvalreqs',
  'attachments',
  'authzactioncheck',
  'bookmarks',
  'connectors',
  'credentials',
  'credentialtypes',
  'googleidproviders',
  'groups',
  'idproviders',
  'idpsecretrefresh',
  'jiraintegrations',
  'jiraprojects',
  'kubenamespaces',
  'objactions',
  'onboardtokens',
  'orgs',
  'pacpolicys',
  'paapolicys',
  'proxies',
  'proxytokens',
  'projects',
  'regions',
  'serviceaccounts',
  'scimservers',
  'servergroups',
  'servers',
  'slackintegrations',
  'slackchannels',
  'sshca',
  'targetgroups',
  'targets',
  'tenants',
  'tenantapikeys',
  'tenantprofiles',
  'tgws',
  'userpreferences',
  'users',
  'vpcs',
  'objaccesscheck',
  'devices',
  'cloudactions',
  'iamactions',
  'awsresources',
  'gcpresources',
  'azureresources',
  'medusanodes',
  'databases',
  'accountcheck'
]

export default function ApiProvider (endpoint = '') {
  if (!validEndpoints.includes(endpoint)) { throw new Error('Invalid Enpoint used. Please check the API specs for the correct endpoint') }

  
  const tenant= JSON.parse(localStorage.getItem('user'))?.tenant;
  const apiKey = localStorage.getItem('authToken');
  // These are constant throughout the app lifecycle
  const user = getUserInfo()

  const { org = 'default' } = user

  // Set the API options from existing data
  this.apiOptions = {
    endpoint,
    tenant,
    org,
    version: 'v1'
  }

  this.dataObj = {}

  const config = {}
  if (apiKey) {
    config.headers = { Authorization: `Bearer ${apiKey}` }
  }

  this.apiOptions.config = config

  // Once set during the construct, it's finalized.
  Object.freeze(this.apiOptions)

  /**
   * Calls callback if provided, else returns a promise
   * @param {*} methodObject - An axios object which returns a promise
   * @param {*} callback - Callback when request is completed
   * @returns
   */
  const callbackOrPromise = (methodObject, callback = null) => {
    if (typeof callback === 'function') {
      // Use this when method has provided a callback function
      /**
       * After it's resolved the returned value will be in format `response` `error`
       * If there's some error, then response = null, and error will be whatever the response is
       * If there is no errors, response will be response we get and error will be null.
       * error can be checked in `if` statement and get the status of the resolved request
       */
      methodObject.then((response) => {
        this.dataObj = response.data
        callback(response, null)
      }).catch((error) => {
        callback(null, error)
        console.error(error)
      })

      return null
    }

    // Else use a promise. Let the method await
    return new Promise((resolve, reject) => {
      methodObject.then((response) => {
        this.dataObj = response.data
        console.log('this.dataObj: ', this.dataObj)
        resolve(response)
      }).catch(reject)
    })
  }

  /**
   * Sets the instance data for the API endpoint
   * @param {object} dataObj - The data to be updated to the given instance
   * @returns
   */
  this.setInstance = (dataObj) => {
    const toSetDataObj = _.cloneDeep(dataObj)
    if (!toSetDataObj.ObjectMeta) { // When ObjectMeta is completely missing
      toSetDataObj.ObjectMeta = {
        Namespace: this.apiOptions.org,
        Tenant: this.apiOptions.tenant 
      }
    } else { // Override the Namespace and Tenant. They do not change
      toSetDataObj.ObjectMeta.Namespace = this.apiOptions.org
      toSetDataObj.ObjectMeta.Tenant = this.apiOptions.tenant 
    }

    this.dataObj = _.merge({}, toSetDataObj)

    return this
  }

  /**
   * Updates the instance data for the API endpoint
   * @param {object} dataObj - The data to be updated to the given instance
   */
  this.updateInstance = (dataObj) => {
    const toSetDataObj = _.cloneDeep(dataObj)
    this.dataObj = _.merge(this.dataObj, toSetDataObj)

    return this
  }
  /**
   * Add unique 6 charater name to the dataobj
   */
  this.addNameToDataObject = () => {
    const uniqueName = randomStringGenerator(6)
    this.dataObj.ObjectMeta.Name = uniqueName
  }

  /**
   * GET All method for the given API endpoint
   * @param {function=} callback - Callback when request is completed
   * @returns
   */
  this.getAll = (callback = null) => {
    const res = callbackOrPromise(
      axios.get(
            `/api/${this.apiOptions.tenant}/${this.apiOptions.version}/${this.apiOptions.endpoint}`,
            this.apiOptions.config
      ),
      callback
    )
    console.log('[API=PROVIDER](getAll):', res)
    return res
  }

  /**
   * GET method for the API endpoint for the set instance
   * @param {*} callback - Callback when request is completed
   * @returns
   */
  this.get = (callback = null) => {
    const name = this.dataObj.ObjectMeta.Name

    if (!name) {
      throw new Error('API Instance not set correctly. Please refer to the API specs.')
    }

    return callbackOrPromise(
      axios.get(
              `/api/${this.apiOptions.tenant}/${this.apiOptions.version}/${this.apiOptions.endpoint}/${this.apiOptions.org}/${name}`,
              this.apiOptions.config
      ),
      callback
    )
  }

  /**
   * POST method for the API endpoint for new data
   * @param {*} callback - Callback when request is completed
   * @returns
   */
  this.post = (callback = null, generateName = false) => {
    if (generateName) this.addNameToDataObject()
    return callbackOrPromise(
      axios.post(
              `/api/${this.apiOptions.tenant}/${this.apiOptions.version}/${this.apiOptions.endpoint}`,
              this.dataObj,
              this.apiOptions.config
      ),
      callback
    )
  }

  /**
   * PUT method for the API endpoint to update a set instance
   * @param {*} callback - Callback when request is completed
   * @returns
   */
  this.put = (callback = null) => {
    const name = this.dataObj.ObjectMeta.Name

    if (!name) {
      throw new Error('API Instance not set correctly. Please refer to the API specs.')
    }

    return callbackOrPromise(
      axios.put(
              `/api/${this.apiOptions.tenant}/${this.apiOptions.version}/${this.apiOptions.endpoint}/${this.apiOptions.org}/${name}`,
              this.dataObj,
              this.apiOptions.config
      ),
      callback
    )
  }

  /**
 * DELETE method for the API endpoint to delete the set instance
 * @param {*} callback
 * @returns
 */
  this.delete = (callback = null) => {
    const name = this.dataObj.ObjectMeta?.Name

    if (!name) {
      throw new Error('API Instance not set correctly. Please refer to the API specs.')
    }

    return callbackOrPromise(
      axios.delete(
              `/api/${this.apiOptions.tenant}/${this.apiOptions.version}/${this.apiOptions.endpoint}/${this.apiOptions.org}/${name}`,
              this.apiOptions.config
      ),
      callback
    )
  }
}

// const myCallback = (response) => {
//   console.log('This is my callback', response.data)
// }

// Get all accounts
// const accounts = new ApiProvider('accounts').getAll(myCallback)

// Get one account
// const currentAccountProvider = new ApiProvider('accounts').setInstance(singleAccountObj)
// const currentAccountData = currentAccountProvider.get(myCallback)

// // To delete
// currentAccountProvider.delete()

// // To update (put)
// currentAccountProvider.updateInstance({ Spec: { somekey: 'someNewValue' } })

// currentAccountProvider.updateInstance({ Spec: { someotherKey: 'someOtherNewValue' } })

// onSubmit = () => {
//   currentAccountProvider.put()
// }

// // New account
// newAccountDetails = {
//   AWSKey: 'some',
//   ObjectMeta: {
//     Name: 'SomeName'
//   }
// }
