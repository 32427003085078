
import { errorHandler, getUserInfo } from '../Utils/Helpers'
import { getElastic } from './NetworkRequest'

export default class activityLogsApis {
  static async getWebProxyActivityLogs () {
    try {
      const user = getUserInfo()
      const { apiKey } = user
      const response = await getElastic(apiKey, 'web-proxy-audit-log/_search/?q=signin.aws.procyon.ai', 'auditlogs')
      return response
    } catch (error) {
      errorHandler(error, 'getWebProxyActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getSshActivityLogs (data) {
    try {
      const user = getUserInfo()
      const { apiKey } = user
      const response = await getElastic(apiKey, 'ssh-audit-log/_search', 'auditlogs')
      return response
    } catch (error) {
      errorHandler(error, 'getSshActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getEventLogs (query = {}) {
    try {
      console.log('getSshActivityLogs event-log/_search query:', query)
      const user = getUserInfo()
      const { apiKey } = user
      const response = await getElastic(apiKey, 'event-log/_search', 'auditlogs', query)
      return response
    } catch (error) {
      errorHandler(error, 'getSshActivityLogs', 'activityLogsApis.js')
    }
  }

  static async getProxyAuditLogs (query = {}) {
    try {
      console.log('getProxyAuditLogs proxy-audit-log/_search query:', query)
      const user = getUserInfo()
      const { apiKey } = user
      const response = await getElastic(apiKey, 'proxy-audit-log/_search', 'auditlogs', query)
      return response
    } catch (error) {
      errorHandler(error, 'getProxyAuditLogs', 'activityLogsApis.js')
    }
  }
}
