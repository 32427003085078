export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'

const initState = {
  loggedIn: false
}
const userReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const userObject = action.payload
      localStorage.setItem('user', JSON.stringify(userObject))
      return {
        ...userObject, loggedIn: true
      } }
    case LOGOUT_USER:
      localStorage.removeItem('user')
      return {
        loggedIn: false
      }
    default:
      return state
  }
}

export default userReducer
