import { API, ApiProvider } from 'Core'
import { populateActionObjects } from 'Utils/ActionEnpointsHelper'
import { populateAuthZ } from 'Utils/ActionZActionCheckHelper'
import { sliceGenerator } from './sliceGenerator'

export const userListSlice = sliceGenerator({
  sliceName: 'userList',
  endpoint: 'users',
  initialState: []
})
export const proxiesSlice = sliceGenerator({
  sliceName: 'proxyList',
  endpoint: 'proxies',
  initialState: []
})
export const groupListSlice = sliceGenerator({
  sliceName: 'groupList',
  endpoint: 'groups',
  initialState: []
})
export const serviceAccountsSlice = sliceGenerator({
  sliceName: 'serviceAccounts',
  endpoint: 'serviceaccounts',
  initialState: []
})
export const projectsSlice = sliceGenerator({
  sliceName: 'projects',
  endpoint: 'projects',
  initialState: []
})
export const idpListSlice = sliceGenerator({
  sliceName: 'idpList',
  endpoint: 'idproviders',
  initialState: []
})
export const policySlice = sliceGenerator({
  sliceName: 'policyList',
  endpoint: 'pacpolicys',
  initialState: []
})
export const paaPolicySlice = sliceGenerator({
  sliceName: 'paaPolicyList',
  endpoint: 'paapolicys',
  initialState: []
})
export const deviceListSlice = sliceGenerator({
  sliceName: 'deviceList',
  endpoint: 'devices',
  initialState: []
})
export const serverListSlice = sliceGenerator({
  sliceName: 'serverList',
  endpoint: 'servers',
  initialState: []
})
export const accountListSlice = sliceGenerator({
  sliceName: 'accountList',
  endpoint: 'accounts',
  initialState: []
})
export const iamActionsSlice = sliceGenerator({
  sliceName: 'iamActions',
  endpoint: 'iamactions',
  initialState: []
})
export const gcpResources = sliceGenerator({
  sliceName: 'gcpResources',
  endpoint: 'gcpresources',
  initialState: []
})
export const azureResourcesSlice = sliceGenerator({
  sliceName: 'azureResources',
  endpoint: 'azureresources',
  initialState: []
})
export const appRolesListSlice = sliceGenerator({
  sliceName: 'appRolesList',
  endpoint: 'approles',
  initialState: []
})
export const medusaNodesSlice = sliceGenerator({
  sliceName: 'medusaNodes',
  endpoint: 'medusanodes',
  initialState: []
})
export const jiraProject = sliceGenerator({
  sliceName: 'jiraProjectList',
  endpoint: 'jiraprojects',
  initialState: []
})
export const recordingListSlice = sliceGenerator({
  sliceName: 'recordingList',
  endpoint: 'recordings',
  initialState: []
})
export const awsResourcesSlice = sliceGenerator({
  sliceName: 'awsResources',
  endpoint: 'awsresources',
  initialState: []
})
export const slackChannel = sliceGenerator({
  sliceName: 'slackChannelList',
  endpoint: 'slackchannels',
  initialState: []
})
export const integrationSlice = sliceGenerator({
  sliceName: 'integrationsList',
  endpoint: 'integrations',
  initialState: []
})
export const approvalReqListSlice = sliceGenerator({
  sliceName: 'approvalReqList',
  endpoint: 'approvalreqs',
  initialState: []
})
export const applicationListSlice = sliceGenerator({
  sliceName: 'applicationList',
  endpoint: 'applications',
  initialState: []
})
export const googleIdpListSlice = sliceGenerator({
  sliceName: 'googleIdpList',
  endpoint: 'googleidproviders',
  initialState: []
})
export const userAccountsListSlice = sliceGenerator({
  sliceName: 'userAccountsList',
  endpoint: 'useraccounts',
  initialState: []
})
export const jiraIntegrations = sliceGenerator({
  sliceName: 'jiraIntegrationsList',
  endpoint: 'jiraintegrations',
  initialState: []
})
export const slackIntegrations = sliceGenerator({
  sliceName: 'slackIntegrationList',
  endpoint: 'slackintegrations',
  initialState: []
})
export const credentialsListSlice = sliceGenerator({
  sliceName: 'credentialsList',
  endpoint: 'credentials',
  initialState: []
})
export const serverGroupListSlice = sliceGenerator({
  sliceName: 'serverGroupList',
  endpoint: 'servergroups',
  initialState: []
})

// export const webProxyActivityLogsSlice = sliceGenerator()
// export const sshActivityLogsSlice = sliceGenerator([], API.getSshActivityLogs, 'sshActivityLogs')
// export const proxyAuditLogsSlice = sliceGenerator([], API.getProxyAuditLogs, 'proxyAuditLogs')
// export const eventLogSlice = sliceGenerator([], API.getEventLogs, 'eventLog')
// export const sshAccessListSlice = sliceGenerator({}, API.getShhAccessList, 'sshAccessList')
// export const roleAccessListSlice = sliceGenerator({}, API.getRoleAccessList, 'roleAccessList')

export const credentialTypeSlice = sliceGenerator({
  sliceName: 'credentialTypesList',
  endpoint: null,
  initialState: [],
  apiFn: API.getCredentialTypesList
})
export const credentialInstanceSlice = sliceGenerator({
  sliceName: 'credentialInstanceList',
  endpoint: null,
  initialState: [],
  apiFn: API.getCredentialInstanceList
})
export const resourceActionsSlice = sliceGenerator({
  sliceName: 'resourceActions',
  endpoint: null,
  initialState: {},
  apiFn: populateActionObjects
})
export const resourceKindGrantsSlice = sliceGenerator({
  sliceName: 'resourceKindGrants',
  endpoint: null,
  initialState: {},
  apiFn: populateAuthZ
})
export const userpreferencesSlice = sliceGenerator({
  sliceName: 'userPreferences',
  endpoint: null,
  initialState: [],
  apiFn: API.fetchUserPreference
})
export const tenantprofilesSlice = sliceGenerator({
  sliceName: 'tenantprofiles',
  endpoint: null,
  initialState: {},
  apiFn: API.getTenantprofiles
})
export const awsCloudActions = sliceGenerator({
  sliceName: 'awsCloudActions',
  endpoint: null,
  initialState: [],
  apiFn: async () => await new ApiProvider('cloudactions').updateInstance({ ObjectMeta: { Name: 'aws' } }).get()
})
export const gcpCloudActions = sliceGenerator({
  sliceName: 'gcpCloudActions',
  endpoint: null,
  initialState: [],
  apiFn: async () => await new ApiProvider('cloudactions').updateInstance({ ObjectMeta: { Name: 'gcp' } }).get()
})
export const azureCloudActions = sliceGenerator({
  sliceName: 'azureCloudActions',
  endpoint: null,
  initialState: [],
  apiFn: async () => await new ApiProvider('cloudactions').updateInstance({ ObjectMeta: { Name: 'azure' } }).get()
})
export const databaseSlice = sliceGenerator({
  sliceName: 'databases',
  endpoint: 'databases',
  initialState: []
})
export const kubeNamespacesSlice = sliceGenerator({
  sliceName: 'kubeNamespaces',
  endpoint: 'kubenamespaces',
  initialState: []
})

export const tenantRiskProfileListingSlice = sliceGenerator({
  sliceName: 'tenantRiskProfileListing',
  endpoint: null,
  initialState: [],
  apiFn: API.getTenantRiskProfileListing
})

export const tenantRiskSummarySlice = sliceGenerator({
  sliceName: 'tenantRiskSummary',
  endpoint: null,
  initialState: {},
  apiFn: API.getTenantRiskSummary
})

export const accountRiskProfileListingSlice = sliceGenerator({
  sliceName: 'accountRiskProfileListing',
  endpoint: null,
  initialState: [],
  apiFn: API.getAccountRiskProfileListing
})

export const accountRiskSummarySlice = sliceGenerator({
  sliceName: 'accountRiskSummary',
  endpoint: null,
  initialState: {},
  apiFn: API.getAccountRiskSummary
})

export const tenantAccountsListSlice = sliceGenerator({
  sliceName: 'tenantAccountsList',
  endpoint: null,
  initialState: [],
  apiFn: API.getTenantAccountsList
})

export const accountGraphSlice = sliceGenerator({
  sliceName: 'accountGraph',
  endpoint: null,
  initialState: [],
  apiFn: API.getAccountGraph
})