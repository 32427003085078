import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { enqueueNotification, errorHandler, getUserInfo } from '../Utils/Helpers'
import { deleteRequest, getRequest, postRequest, putRequest } from './NetworkRequest'
import { local } from 'd3'

export class IdAnalyzerApis {
  // data table 
  static async getTenantRiskProfileListing () {
    try {
      const token = localStorage.getItem('authToken');
      const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
      const response = await getRequest(token, 'resourcediscoverytenantriskprofile', tenant)
      return response && response.data ? response.data.ResourceDiscoveryTenantRiskProfiles : []
    } catch (error) {
      errorHandler(error, 'getTenantRiskProfileListing', 'idanalyzer.js')
    }
  }

  // chart plus summay data
  static async getTenantRiskSummary () {
    try {
      const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
      const token = localStorage.getItem('authToken');
      const response = await getRequest(token, `resourcediscoverytenantsummary/default/${tenant}`, tenant)
      return response && response.data ? response.data : {}
    } catch (error) {
      errorHandler(error, 'getTenantRiskSummary', 'idanalyzer.js')
    }
  }

  static async getAccountRiskProfileListing () {
    try {
      // const user = await getUserInfo()
      // const { tenant, apiKey } = user
      const accountName = localStorage.getItem('accId')
      const token = localStorage.getItem('authToken');
      const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
      const response = await getRequest(token, `resourcediscoveryaccountriskprofiles?ObjectMeta.QueryParam=accountName:${accountName}`, tenant)
      return response && response.data ? response.data.ResourceDiscoveryAccountRiskProfiles : []
    } catch (error) {
      errorHandler(error, 'getTenantSummary', 'idanalyzer.js')
    }
  }

  // account summary with listing - account details
  static async getAccountRiskSummary () {
    try {
      const getID = localStorage.getItem('accId')
      const token = localStorage.getItem('authToken');
      const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
      const response = await getRequest(token, `resourcediscoveryaccountsummary/default/${getID === 'undefined' ? '' : getID}`, tenant)
      return response && response.data ? response.data : {}
    } catch (error) {
      errorHandler(error, 'getTenantAccountSummary', 'idanalyzer.js')
    }
  }


static async getTenantAccountsList () {
  try {
    const user = getUserInfo()
    const token = localStorage.getItem('authToken');
    const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
    const response = await getRequest(token, `resourcediscoveryaccountsummary`, tenant)
      return response && response.data ? response.data.ResourceDiscoveryAccountSummarys : []
  } catch (error) {
    errorHandler(error, 'getTenantAccountsList', 'idanalyzer.js')
  }
}


static async getAccountGraph () {
  try {
    const token = localStorage.getItem('authToken');
    const verID = localStorage.getItem('verID');
    const tenant = JSON.parse(localStorage.getItem('user'))?.tenant;
    const response = await getRequest(token, `resourcediscoveryaccountriskprofiles/default/${verID}`, tenant)
      return response && response.data ? response.data : []
  }catch(error) {
    errorHandler(error, 'getAccountGraph', 'idanalyzer.js')
  }
}}
